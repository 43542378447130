body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "palaquin";
  src: url("/public/fonts/palanquin-medium.ttf") format("truetype");
}

body {
  background-color: rgb(244, 245, 248);
  font-family: "palaquin";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/img/fonds.png");
  background-size: cover;
  
}
.flex-1{
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.carousel {
  width: 60% !important;
}
.carousel-item {
  width: 50% !important;
  object-fit: cover;
}

.img1 {
  background-image: url("./assets/img/img1.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 15em;
}
.img-categorie {
  margin: auto;
}
.img-categorie img {
  width: 55%;
  height: auto;
}

.text-categorie {
  font-size: "1.25vw";
  font-weight: 500;
  
}
.marge-haut {
  margin-top: 50%;
}
.categorie {
  border: 0.25vw solid #00884a;
  font-weight: 1000;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  color: #00884a;
}
.groupe-module {
  width: auto;
  margin-top: 1%;
  height: auto;
}
.example {
  position: relative;
  padding: 0;
  width: 300px;
  display: block;
  cursor: pointer;
  overflow: hidden;
}
.content {
  opacity: 0;
  font-size: 40px;
  position: absolute;
  top: 0;
  left: 0;
  color: #1c87c9;
  background-color: rgba(200, 200, 200, 0.5);
  width: 300px;
  height: 300px;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
}


.menu {
  text-align: center;
  align-items: center;
}

a {
  text-decoration: none;
}

.btn-accueil {
  width: min-content;
}

.exercices {
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
}
.img-exercices {
  width: 8vw;
}
.text-exercices {
  color: #00884a;
  font-size: 2.1vw;
  font-weight: 600;
}

.categorie:hover {
  background-color: white;
}

.dropdown {
  margin-left: 83%;
}
.dropdown button::after{
  display: none;
}
.dropdown button:checked{
  background-color:rgba(255, 255, 255, 0);
  border: none;
}
.dropdown button:hover{
  background-color:rgba(255, 255, 255, 0);
  border: none;
}
.dropdown button:active{
  background-color:rgba(255, 255, 255, 0);
  border: none;
}
.dropdown button{
  margin-top: -5%;
  width: 70%;
  height: 4vw;
  background-color:rgba(255, 255, 255, 0);
  border: none;
  text-decoration: none;
  color: #043E7E;

  background-repeat: no-repeat;
  background-size:contain;
  background-image: url("/public/img/bouton-parametre.png");
  background-position: 50%;
}

.child:active, :not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color);
  border-color: var(--bs-btn-active-border-color);
}
.btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color);
  /* background-color: var(--bs-btn-active-bg); */
  /* border-color: var(--bs-btn-active-border-color); */
}

.langue {
  text-transform: uppercase;
}

.theme {
  /*border: 1px solid ;*/
  margin: auto;

  text-align: center;
  position: relative;
  top: 3.5vw;
  color: #00884a;
  text-transform: uppercase;
  font-size: 2.2vw;
  font-weight: 600;
  width: 50%;
}

.africatik {
  margin-left: 5vw;
  width: 65%;
  
  
}
.nseeba {
  border-left: 2px solid #00884a;
  padding-left: 1vw;
  margin-left: 20%;
  width: 100%;
  height: auto;
}
.africatik-logo .logo-africatik {
  text-align: end;
  display: inline-block;
  padding-left: 20%;
}
.btn-primary {
  --bs-btn-color: #fff;
 
  --bs-btn-hover-color: #fff;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
}

.logo-appli {
  margin-top: 1%;
  width: 10vw;
}
.parametre {
  margin-left: 10%;
}

.titre {
  display: inline-block;
}

.onglet {
  margin: auto;
  height: auto;
}
.image {
  max-width:"100%";
  height:auto
}
.onglet a {
  text-decoration: none;
  color: #00884a;
}
.onglet li {
  border-radius: 50%;
  margin-left: 10%;
  margin-right: 2.32%;
  color: black;
  list-style: none;
  text-decoration: none;
  width: 3.2%;
}
.onglet .nav-tabs {
  border: none;
  margin: auto;
  background-image: url("/public/img/module1-traitchemindefer-if.png");

  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  color: #00884a;
  width: 100%;
  /* border: 2px solid #;*/
}

.onglet .nav-link {
  background-color: rgb(158, 201, 255);
  color: black;
  font-size: 1.2vw;
  font-weight: 600;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.onglet .nav-link.active {
  background-color: yellow;
  border: none;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.onglet .nav-link.disabled {
  background-color: rgb(82, 82, 81);
  color: black;
  border: none;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.onglet .nav-link:hover {
  border-radius: 50%;
}

.lesson-img-content {
  margin: auto;
  padding: auto;
  width: auto;
}
.lesson-img {
  margin: auto;
  margin-bottom: 2%;
  background-color: rgb(255, 255, 255);
  border-radius: 1.5vw;
  width: 70%;
}

.backgr {
  background-color: #00884a;
}

.lesson-texte {
  padding: 1%;
  width: 48vw;
  text-align: justify;
  height: 29vw;
  color: #00884a;
  overflow: scroll;
}

.lesson-texte li {
  width: auto;
  list-style: disc;
  color: #00884a;
  text-align: justify;
}

.centrer {
  text-align: center;
}

.texte-choix-reponse {
  margin: auto;
  color: #fff;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  height: 10%;
  width: 100%;
  font-size: 2vw;
}
.texte-choix-reponse h3 {
  font-size: 2vw;
  margin-top: 2%;
}
.question-quiz {
  margin: auto;
  margin-top: 4%;
  width: 100%;
  font-size: 1.25vw;
}

.reponses-quiz {
  margin: auto;
  margin-top: 2%;
  text-align: center;
  font-size: 1.5vw;
  color: "#60523B";
}

.reponses-quiz button {
  margin-bottom: 1%;
  background-color: white;
  border: none;
  border-radius: 10px;
  color: #00884a;
  width: 100%;
  height: 90%;
}

.quiz-content {
  color: "green";
  border: 2px solid #00884a;
  border-radius: 30px;
  margin: auto;
  margin-top: 1%;
  width: 50%;
  height: 100%;
  padding-bottom: 1%;
}

.image-question-savoir {
  margin: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  width: 25vw;
  height: 25vw;
}

.btn-savoir-faire {
  margin-top: 1%;
  padding-top: 3%;
  border-radius: 10px;
  background-color: white;

  font-size: 1.5vw;
  width: 100%;
}

.reponse-savoir-faire {
  margin-top: 0.01%;
  -ms-transform: translateY(3%);
  transform: translateY(3%);
}

.titres {
  margin: auto;
  padding-left: 3%;
  padding-right: 3%;
  width: 100%;
  color: #00884a;
  font-size: 1.1vw;
  font-weight: 600;
}
.titres p {
  text-align: center;
}

.texte-etape {
  margin: 0.2%;
  background-color: white;
  border-radius: 2vw;
  border: none;
  height: min-content;
}

.content-texte-etape,
.content-numero-etape {
  font-size: 1.7vw;
}

.noBorder {
  border: none;
}

.numero-etape {
  margin-bottom: 2%;
  margin-top: 1%;
  margin-right: 1%;
  border: 2px solid;
  border-radius: 3vw;
  background-color: #00884a;
  color: white;
  text-align: center;
  height: 3vw;
  width: 3vw;
}

.groupe-btn-son {
  padding-top: 2%;
  padding-bottom: 2%;
  margin-top: 2%;
  margin: auto;
  width: fit-content;
  border-radius: 1vw;
  height: fit-content;
}
.groupe-btn-son button {
  background-color: white;
  margin: auto;
  border: none;
  border-left: 1px solid;
  border-right: 1px solid;
}

.groupe-btn-son button img {
  width: 5vw;
  height: 4vw;
}

.groupe-btn-son audio {
  height: 3vw;
}

.case1 {
  background-color: #00884a;
}
.case2 {
  background-color: red;
}

#finpartie {
  padding-top: 10%;
  padding-bottom: 10%;
  margin: auto;
  background-color: white;
  border-radius: 100%;
  width: 100%;
}

.finpartie-btn-groupe {
  margin: 20vw;
  width: 35%;
  height: 100%;
  margin-top: 50%;
}

.finpartie-text {
  text-align: center;
  color: green;
  margin-bottom: 2%;
}

.accueil {
  height: auto;
  width: 7vw;
  padding-top: 1%;
}

.page-centre {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 90vh;
  -ms-flex-pack: center;
  justify-content: center;
}

/* Mes styles  */
.position-Btn-left {
  margin-left: 10%;
  margin-bottom: 30%;
}
.position-Btn-right {
  padding-left: 40px;
}
.position-Btnleft {
  margin-left: 10%;
  margin-bottom: 30%;
  margin-top: -30%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 30%;
  padding-left: 10%;
}

.card2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 60%;
  padding-right: 50%;
}
.card-niveaux {
  width: 100%;
  margin-left: -3%;
  margin-top: -8%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.activite {
  margin-right: 25%;
}
.activite2 {
  text-align: center;
  color: beige;
  font-weight: bolder;
  font-size: x-large;
  text-transform: uppercase;
  
}

.container {
  background-color: #00884a;
  transform: rotate3d(1, 1, 1, -3deg);margin-top: 5%;
  
  
  
}
.container2 {
  background-color: #00884a;
  transform: rotate3d(1, 1, 1, -3deg);
  margin-top: 2%;
}

.img-act {
  width: 17.9vw;
}

.img-act-niveau {
  width: 4vw;
  padding-top: 20%;
}
.align {
  padding-bottom: 2%;
  padding-top: 80px;
}
.align2 {
  padding-bottom: 5px;
  padding-top: 70px;
}

.aligBtn {
  
}
.align-card {
  padding-left: 100px;
}
.txt-niv2 {
  padding-left: 13%;
  border-color: #00884a;
  border-width: 2px;
}
.txt-niv {
  width: 30px;

  padding-left: 10px;
  text-align: center;
  font-size: 1.5vw;
  font-weight: bold;
}
.niv-1 {
  margin-left: 15%;
  padding-top: 25%;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}

.btn-test:focus{
background-color: #00884a;
}
.niv-2 {
  padding-top: 52%;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.niv-3 {
  margin-top: -4%;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}
.down-niv {
  position: relative;
  padding-top: 10%;
}
.down-niv1 {
  position: relative;
  padding-top: 12%;
  margin-left: 10%;
}
.img-fluid2 {
  background-color: rgb(205, 112, 50);
}

.fond-txt {
  /* background-image: url("../public/img/objet-titre.png"); */
  background-color: yellow;
}

.click:hover {
  transform: scale(0.7);
  transition-duration: 0.2s;
  position: relative;
}
.click:focus {
  transform: scale(0.7);
  transition-duration: 50s;
  position: relative;
}
.hover-div {
  background-color: yellow;
  width: 100%;
}
.centered {
  transform: translate(-50%, -50%);
  
  color: #00884a;
  font-size: 1vw;
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  writing-mode: lr-tb;
  margin-top: -35%;
  margin-left: 10%;
}
.centered1 {
  margin-top: -35%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #00884a;
  font-size: 1vw;
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  writing-mode: lr-tb;
  margin-top: -35%;
  margin-left: -18%;
}
.centre{
  transform: translate(-50%, -50%);
  font-size: 1vw;
  -webkit-transform: rotate(-6deg);
  -moz-transform: rotate(-6deg);
  -o-transform: rotate(-6deg);
  writing-mode: lr-tb;
}

.img {
  position: absolute;
  top: 92%;
  margin-left: 2.5vw;
  transform: translate(-50%, -50%);
}
.img1 {
  position: absolute;
  top: 95%;
  height: 2vw;
  margin-left: 9vw;
  transform: translate(-50%, -50%);
}

.container-btn {
  position: relative;
}

.contenair-txt {
  position: absolute;
  margin-top: -55%;
  margin-left: 15%;
}
.contenair-txt1 {
  position: absolute;
  margin-top: -55%;
  margin-right: 30%;
}

.div2 {
  padding-left: 20%;
}
/* Style Module 4 */
.help {
  color: aliceblue;
  font-size: 50px;
  position: absolute;
  background-color: tomato;
  border: #00884a;
}
.body-help {
  padding-top: 32%;
  padding-left: 2%;
}
.body-mod1 {
  background-color: #00884a;
  height: 100%;
  width: 120%;
}
.body-text-col {
  color: #fff;
}
.body-text {
  font-size: 1.2vw;
  color: #fff;
}

.mod-4 {
  background-size: contain;
}
.width-barre-titre {
  position: absolute;
}
.parametre button:hover{
background-color: 'red';
}
.body-img {
  padding-top: 2%;
}
.entete-mod4 {
  background-color: #00884a;
  width: 97vw;
  height: 4vw;
}
.cases-point {
  margin: auto;
  width: 45vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
}
.cases-point3 {
  margin: auto;
  width: 30vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
}
.case {
  text-align: center;
  border: 2px solid #00884a;
  border-right: none;
  border-bottom: none;
  border-top: none;
  height: auto;
  width: 1vw;
}
.cases-point1 {
  margin: auto;
  width: 22vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
  position: relative;
}
.cases-point2 {
  margin: auto;
  width: 45vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
  position: relative;
}
.cases-pointMod1 {
  margin: auto;
  width: 20vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
  position: relative;
}
.cases-pointMod2 {
  margin: auto;
  width: 30vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
  position: relative;
}
.cases-pointMod3 {
  margin: auto;
  width: 38vw;
  height: 100%;
  border: 2px solid #00884a;
  border-left: none;
  position: relative;
}
.case1 {
  text-align: center;
  border: 2px solid;
  border-right: none;
  border-bottom: none;
  border-top: none;
  height: auto;
  width: 2vw;
}
.case-pt {
  position: absolute;
  padding-top: -100%;
  margin-left: 80%;
}
.img-Illustration {
  margin: auto;
}
.barre_title_img {
  width: 100%;
  position: absolute;
  margin-top: 2%;
  margin-left: 13%;
}
.barre_title_img1 {
  width: 100%;
  position: absolute;
  margin-top: 2%;
  margin-left: 50%;
}

.column {
  padding-top: 3%;
}
.column-btn {
  margin-left: 8%;
  margin-top: 2%;
}
.column-btn-two {
  margin-left: 10%;
  margin-top: 2%;
}
.text-img-yellow {
  position: absolute;
  color: #00884a;
  margin-top: -40%;
  transform: translate(-50%, -50%);
  font-size: 1vw;
  -webkit-transform: rotate(-7deg);
  -moz-transform: rotate(-7deg);
  -o-transform: rotate(-7deg);
  writing-mode: lr-tb;
  margin-left: 5%;
}

.img-yellow {
  width: 12.7vw;
}
.img-yello-absolute {
  position: absolute;
  margin-left: -4%;
  margin-top: -2%;
}
.img-yello-absolute-two {
  position: absolute;
  margin-left: -4%;
  margin-top: -2%;
}
.img-play {
  position: absolute;
  margin-top: 10%;
  margin-left: 2%;
}
.img-play-two {
  position: absolute;
  margin-top: 9.8%;
  margin-left: 2%;
}
